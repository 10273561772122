import React from "react";
import ReactGA from "react-ga4";
import "./history.scss";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function History() {
  useDocumentTitle("History");
  ReactGA.send({ hitType: "pageview", page: "/history" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/history-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">History & Reflection</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="history">
        <div className="container py-5">
          <header className="mb-4 text-center">
            <p className="text-serif mb-0 text-primary">Started with</p>
            <h2 className="text-uppercase">The Foundation</h2>
          </header>
          <div className="row gy-4">
            <div className="col order-2">
              <p>
                On the evening of August 12, 2023, a small group of Indian
                Christians of CSI heritage gathered in the home of Pastor
                Thinagar Sitther in Denton, Texas, to receive pastoral care and
                comfort. The group had experienced trauma and discord in their
                previous parish. Pastor Thinagar, and his wife, Perpetua Sitther
                (affectionately known as Petti Auntie) spent not only that
                evening but for the next several months consoling, nurturing,
                and galvanizing this group of believers. “Blessed are the
                peacemakers for they shall be called children of God (Matthew
                5:9).” Our gratitude towards Pastor Thinagar and Petti Auntie
                for enabling God`s comfort and peace cannot be overstated. We
                truly thank God for them.
              </p>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-3">
              <img
                src="/img/history-foundation.jpg"
                className="img-fluid"
                alt="History Foundation"
              ></img>
            </div>
          </div>
          <header className="mt-4 mb-4 text-center">
            <p className="text-serif mb-0 text-primary">Then, became</p>
            <h2 className="text-uppercase">The Fellowship</h2>
          </header>
          <div className="row gy-4">
            <div className="col-12 col-lg-6">
              <img
                src="/img/history-fellowship.jpg"
                className="img-fluid"
                alt="History Fellowship"
              ></img>
            </div>
            <div className="col">
              <p>
                This small group of believers resolved to organize as a body of
                Christ. We took the initiative to contact the CSI Diaspora
                Diocese for guidance and direction. Daniel Cherian, the
                appointed Treasurer of the Administrative Committee of the
                Diocese played a pivotal role in advising the group. In the
                ensuing days and months this faithful group of people worshipped
                in one another`s homes and prioritized Christian education of
                the children.
              </p>
              <p>
                The first step of our development was forming a CSI Fellowship
                and assigning a leadership team. In September of 2023, Mr. Prem
                Babu was named President, Mr. Joshua Winston as Vice President,
                Mr. Daniel Jebakumar as Secretary, Mrs. Jenifer Jeyaraj as
                Treasurer, and Mrs. Steffi Fedrick as Joint Secretary. This
                leadership team, in the span of five months, did an amazing job.
                Amongst their many accomplishments was their commitment to CSI
                doctrines, to democratic principles, to transparency, to
                children`s ministry, to programs like the retreat that
                strengthened our bonds, the establishment of consistent worship
                timing (Saturday evenings), and to identifying a location to
                regularly meet. We are grateful to St. Andrew`s Episcopal
                Church, McKinney, Texas for providing us with our first location
                of worship. Further, the leadership team was able to ensure the
                group met all federal and state guidelines by incorporating as a
                non-profit corporation, ascertaining tax filing status as
                501(c)(3) organization, and forming a bank account.
              </p>
            </div>
          </div>
          <header className="mt-4 mb-4 text-center">
            <p className="text-serif mb-0 text-primary">And a new begining with</p>
            <h2 className="text-uppercase">The Church</h2>
          </header>
          <div className="row gy-4">
            <div className="col order-2">
              <p>
                Under the auspices of this leadership team the name “Love of
                Christ CSI Church” was coined. Truly, we are thankful for the
                divinely inspired name, “Love of Christ CSI Church.” As a
                congregation we pledge to always share the love of Christ with
                His creation. On February 10, 2024, the CSI Diaspora Diocese
                Administrative Committee granted our church “Associate Member”
                status. On February 21, 2024, the Bishop-In-Charge of the
                Diaspora Diocese and the then Deputy Moderator of the Church of
                South India, the Rt. Rev. Reuben Mark, appointed the Rev.
                Franklin Sherwin Doss, as Presbyter-In-Charge of our parish.
                This leadership team`s efforts culminated in the General Body
                Meeting of February 25, 2024, where we approved a constitution
                and formally elected our first Church Committee.
              </p>
              <p>
                The first elected church committee consisted of Mr. Stanlin
                Jebaraj, Mr. Anil Chandy, Mr. Daya Emmanuel, Mr. Daniel
                Jebakumar, Mrs. Daphne Gilbert, Mr. Ryan Thomas, and Mrs. Steffi
                Fedrick. The new church committee`s initial acts included moving
                worship timing to Sunday mornings and to commemorate God`s
                benevolence towards us with a Special Service of Thanksgiving on
                Saturday, April 27, to proclaim, in the great assembly, our
                thankfulness. We are grateful to Faith United Presbyterian
                Church for providing us with a sanctuary to worship on Sunday
                mornings. Additionally, we acknowledge the contributions of the
                Rev. Franklin Sherwin Doss for his guiding presence since
                November 2023.
              </p>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-3">
              <img
                src="/img/history-church.jpg"
                className="img-fluid"
                alt="History Church"
              ></img>
            </div>
            <div className="col-12 order-4">
              <p>
                Our journey as Love of Christ CSI Church has been humbling and
                spiritually rewarding. Along the way there have been countless
                members, friends, and acquaintances whom God has placed in our
                journey that have provided invaluable help towards the success
                of this congregation, which number too many to name here. We are
                also deeply appreciative of the various ministries of the church
                that have worked tirelessly to ensure a meaningful worship
                experience, a vibrant fellowship, and a welcoming environment.
                Truly, we are grateful to God for them all. We hope that God
                would continue bless our efforts towards advancing His Gospel in
                this world. Pray for us and our leadership that we may “show
                forth the excellencies of Him who called us out of darkness into
                his marvelous light (1 Peter 2:9)”.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-2" id="pastoral-history">
        <div className="container">
          <header className="mb-4 text-center">
            {/* <p className="text-serif mb-0 text-primary">Started with</p> */}
            <h2 className="text-uppercase">Pastoral History</h2>
          </header>
        </div>
      </section>

      <section id="pastor_sitther" style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <div className="container-fluid px-0">
          <div className="row align-items-stretch">
            <div
              className="col-lg-6 half-page-banner bg-cover"
              style={{ background: "url(img/pastor_sitther.jpg)" }}
            ></div>
            <div className="col-lg-6 bg-light py-lg-5">
              <div className="h-100 d-flex flex-column p-5 justify-content-center">
                <p className="text-serif text-primary mb-0">Founding Pastor</p>
                <h2 className="text-uppercase">Pastor Thinagar Sitther</h2>
                <p className="text-muted"></p>
                <ul className="list-check ps-0">
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      He is a graduate of Hesston College, Hesston, Kansas.
                    </p>
                  </li>
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      He is an ordained minister of the Mennonite Church USA.
                    </p>
                  </li>
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      He founded and planted Asian Indian Christian Church of
                      Pittsburgh in 1999.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
