import React from "react";

import "./pastors.scss";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function Pastors() {
  useDocumentTitle("Pastors");
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/pastors-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Our Pastors</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5">
          <p>
            At Love of Christ CSI Church, our leadership is guided by a team of
            compassionate and devoted pastors. Our pastoral team is committed to
            shepherding the flock, providing spiritual guidance, and fostering a
            nurturing environment for growth and fellowship. Get to know the
            hearts and stories behind our pastors who are passionate about
            serving the Love of Christ CSI community.
          </p>
        </div>
      </section>

      <section id="pastor_sherwin">
        <div className="container-fluid px-0">
          <div className="row align-items-stretch">
            <div className="col-lg-6 bg-light py-lg-5 small_order_2">
              <div className="h-100 d-flex flex-column p-5 justify-content-center">
                <p className="text-serif text-primary mb-0">
                  Presbyter In-Charge
                </p>
                <h2 className="text-uppercase">The Rev. Sherwin Doss</h2>
                <p className="text-muted">
                  His passion is for missions and he has a heart for pastoral
                  care. He is married to Anitha for 25 years, and God has
                  graciously blessed them with a daughter, Ruah.
                </p>
                <ul className="list-check ps-0">
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      Dedicated to ministry in 1993 and was ordained in 1998 in
                      Karnataka Central Diocese of the Church of South India.
                    </p>
                  </li>
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      He completed Masters in Sacred Theology and graduated in
                      2006 at Dallas Theological Seminary, Texas.
                    </p>
                  </li>
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      He completed Clinical Pastoral Education (CPE) and serves
                      as a Chaplain with Baylor Scott & White Healthcare System.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-6 half-page-banner bg-cover"
              style={{
                background: "url(img/pastor_sherwin.jpg)",
                backgroundPositionX: 'right',
                minHeight: "17rem",
              }}
            ></div>
          </div>
        </div>
      </section>
      <section
        id="pastor_madhavaraj"
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        <div className="container-fluid px-0">
          <div className="row align-items-stretch">
            <div
              className="col-lg-6 half-page-banner bg-cover bg-center"
              style={{ background: "url(img/rev_samuel_madavaraj.jpg)",
                backgroundPositionX: 'right',
                minHeight: "25rem",
               }}
            ></div>
            <div className="col-lg-6 bg-light py-lg-5">
              <div className="h-100 d-flex flex-column p-5 justify-content-center">
                <p className="text-serif text-primary mb-0">Pastor Emeritus</p>
                <h2 className="text-uppercase">
                  The Rev. Dr. Samuel Madavaraj
                </h2>
                <p className="text-muted">
                  He was born in 1951 as the eldest son in a Pastor’s home in
                  Chennai. After his college studies he worked in a Government
                  job from 1971–77. He felt that God was calling him to the
                  Pastoral Ministry. He resigned his well paying job and trusted
                  God for the future provisions.
                </p>
                <ul className="list-check ps-0">
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      He completed Bachelor of Divinity in India in 1981 and was
                      ordained as a Presbyter by the Bishop in 1980. He served
                      in the CSI Madras Diocese for 10 years.
                    </p>
                  </li>
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      In 1991 he came to USA to pursue his higher studies and holds
                      2 doctorates - PhD in Old Testament and ThD in theology.
                      He served as presbyter for various CSI and CNI background
                      churches in United States.
                    </p>
                  </li>
                  <li>
                    <p className="mb-0 text-muted mb-2">
                      His wife is Julie and he is married to her for 41 years.
                      They are blessed with a son and a daughter and 3
                      grandchildren.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
