import { useRef, useState } from "react";

export default function SiteMusic() {
  const [muted, setMute] = useState(true);
  const audioEle = useRef();

  const toggleMusic = () => {
    audioEle?.current?.play();
    if (muted) {
      setMute(false);
    } else {
      setMute(true);
    }
  };

  return (
    <>
      <audio ref={audioEle} autoPlay playsInline loop muted={muted}>
        <source src="./christmas_music.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <a className="scroll-top visible push-left" href>
        {muted && (
          <i
            className="bi bi-volume-mute-fill h3 text-light"
            onClick={() => toggleMusic()}
          ></i>
        )}
        {!muted && (
          <i
            className="bi bi-volume-up-fill h3 text-light"
            onClick={() => toggleMusic()}
          ></i>
        )}
      </a>
    </>
  );
}
